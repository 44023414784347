<template>
  <div @dragstart="dragstart" draggable="true" :key="engineer.id + '-engineer-day-' + job.id" class="card text-white" :class="{'bg-success': job.pending === 0, 'bg-primary': job.pending === 1, 'bg-danger': job.to_delete === 1}">
      <div class="card-body">
        <p class="card-text">{{job.client_name}} - {{job.site_name}} - {{job.postcode}} - <span class="job-number" style="cursor: pointer;" @click="$router.push({name: 'admin.jobs.view', params: {id: job.id}})">JR {{job.id}}</span></p>
      </div>
  </div>
</template>
<script>
export default {
  name: 'ScheduleJobMini',
  props: {
      job: {
          type: Object,
          required: true
      },
      engineer: {
          type: [Object, Number],
          required: true
      },
      day: {
          type: [String, Number],
          required: true
      },
  },
  methods: {
      dragstart(event){
          let day = -1;
          if(this.day !== -1){
              day = this.day.toFormat('ccc dd');
          }
          event.dataTransfer.setData("job", JSON.stringify({
              job: this.job.id,
              engineer: this.engineer?.id || -1,
              day: day
          }));
      }
  }
}
</script>

<style scoped>
.card-body {
  padding: 0.1rem;
}
.card-text {
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}
</style>