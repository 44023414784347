<template>
  <div class="row">

<!--    <div class="col-3">-->
<!--      <div class="card">-->
<!--        <div class="card-body" style="max-height: 80vh; overflow-y: scroll;">-->
<!--          <h5 class="card-title">Open Jobs</h5>-->
<!--          <br/>-->
<!--          <div v-for="job in jobs" :key="'job-' + job.id" class="job card radius-10" vx="0" vy="0">-->
<!--            <div class="card-body">-->
<!--              <div class="d-flex align-items-center">-->
<!--                <secure-image v-if="job.client_logo" class="rounded-circle p-1 border" width="90" height="90" :id="job.id + '-logo'" :endpoint="`/v1/client/${job.client_id}/logo`"></secure-image>-->
<!--                <div class="flex-grow-1 ms-3">-->
<!--                  <h5 class="mt-0">Job #{{job.id}}</h5>-->
<!--                  <p class="mb-0"><strong>{{job.site_name}}:</strong> {{job.description.substring(0, 50)}}...</p>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

    <div class="card schedule-hotbar bg-dark">
      <div class="card-body">
        <div class="row">
          <div class="col-2">
            <button class="btn btn-sm btn-primary" style="margin-top: 7px;" @click="$router.push('/p')">Home</button>
          </div>
          <div class="col-8" style="display: flex; justify-content: center;">
            <h2 v-if="!loading"><i class='bx bx-chevron-left' style="cursor: pointer;" @click="changeWeek(-1)"></i> Week {{startOfWeek.weekNumber}} <i class='bx bx-chevron-right' @click="changeWeek(1)" style="cursor: pointer;"></i></h2>
          </div>
          <div class="col-2">
            <button class="btn btn-sm btn-primary float-end" style="margin-top: 7px;" @click="openNoteModal">Add Note</button>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-2" style="margin-top: 55px;">
      <div class="card" style="margin-left: 10px;">
        <div class="card-body" style="max-height: 95vh; min-height: 95vh; max-height: 95vh; overflow-y: scroll;">

          <div class="row">
            <div class="col-12">
              <div class="form-group" style="padding: 0.5rem;">
                <label for="jobs-filter">Filter Jobs</label>
                <input type="text" class="form-control" id="jobs-filter" v-model="jobFilter">
              </div>
            </div>
          </div>

          <div class="row" style="margin-top: 10px;">

            <div class="col-12">
              <scheduled_jobs style="margin-left: 10px; margin-right: 10px;" v-for="(job) in filteredJobs" :job="job" :engineer="{id: -1}" :day="-1" :key="`job-${job.id}-eng--1-day-noday`"></scheduled_jobs>
            </div>

          </div>

        </div>
      </div>
    </div>
    <div class="col-xl-10" style="margin-top: 55px;">
      <div class="card ">
        <div class="card-body" ref="schedule_container" style="overflow: scroll; max-height: 95vh; height: 95vh;">

          <table v-if="!loading" class="table schedule-table" style="max-width: 100%; height: 100%; min-height: 750px;">
            <thead>
              <tr>
                <th style="min-width: 1.5rem; border-right: 1px solid; position: sticky;"></th>
                <th class="text-center" v-for="e in filteredUsers" :key="e.id + '-engineer'" :style="`border-right: 1px solid; min-width: ${e.title_length || 25}ch;`">
                  <i class='bx bx-chevron-left' @click="moveEngineer(e, -1)" style="cursor: pointer;"></i>
                  {{e.fname.substr(0, 1)}} {{e.lname}}
                  <i class='bx bx-chevron-right' @click="moveEngineer(e, 1)" style="cursor: pointer;"></i>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="day in currentWeek" :key="'day-'+day.toFormat('ccc dd')" style="min-height: 100px; height: 100px;">
                <td class="text-center" style="vertical-align: middle; border-right: 1px solid; position: sticky; text-orientation: mixed; writing-mode: vertical-lr; transform: scale(-1)"><strong>{{day.toFormat('ccc dd')}}</strong></td>
                <td class="text-center" :class="{'disabled-day': isAnnualLeave(day, e)}" v-for="e in filteredUsers" @dragover="(event) => dragover(event, e, day)" @drop="(event) => drop(event, e, day)" :key="e.id + '-engineer-day'" style=" border-right: 1px solid; position: relative;">
                  
                  <div v-if="isAnnualLeave(day, e)" style="line-height: 100%; height: 100%; text-align: center;">
                    <p class="text-white card-title" style="line-height: 1.5; display: inline-block; vertical-align: middle;">Annual Leave</p>
                  </div>
                  <ScheduleJobMini v-for="(job) in e.jobs[day.toFormat('ccc dd')]" :job="job" :engineer="e" :day="day" :key="`job-${job.id}-eng-${e.id}-day-${day.toFormat('ccc dd')}`"></ScheduleJobMini>
                  <div v-for="(note, index) in e.notes[day.toFormat('ccc dd')]" :key="e.id + '-engineer-day-' + note.id" class="card text-white" style="background-color: black;">
                    <div class="card-body" style="background-color: black;">
                      <h5 class="card-title text-white">
                        <span v-if="note.to_delete !== 1" class="float-end text-danger" style="cursor: pointer;" @click="removeNote(e.id, day, index)"><i class='bx bx-trash' ></i></span>
                        <span v-else class="float-end text-warning" style="cursor: pointer;" @click="undoRemoveNote(e.id, day, index)"><i class='bx bx-undo' ></i></span>
                      </h5>
                      <p class="card-text">{{note.note}}</p>
                    </div>
                  </div>

                </td>
              </tr>
            </tbody>
          </table>

          <div v-show="loading" class="spinner-border" role="status"> <span class="visually-hidden">Loading...</span>
          </div>

        </div>
      </div>
    </div>

    <div class="modal fade" id="createScheduledNoteModal" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Create Note</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12 mb-2">
                <p>Fields marked <span class="text-danger">*</span> are required</p>
              </div>
              <div class="col-md-12 mb-2">
                <label for="noteAudience" class="form-label">Who's the note for? <span class="text-danger">*</span></label>
                <Multiselect v-model="note.audience" id="noteAudience" valueProp="id" label="name" :searchable="true" :options="[
                  {
                    id: 'all',
                    name: 'Everyone'
                  },
                  {
                    id: 'engineers',
                    name: 'Engineers'
                  },
                  {
                    id: 'office',
                    name: 'Office'
                  },
                  ...filteredUsers
                ]"></Multiselect>
              </div>
              <div class="col-md-12 mb-2">
                <label for="noteType" class="form-label">What type of note is it? <span class="text-danger">*</span></label>
                <Multiselect v-model="note.type" id="noteType" valueProp="id" label="name" :searchable="true" :options="[
                  {
                    id: 'all',
                    name: 'Overall'
                  },
                  {
                    id: 'daily',
                    name: 'Daily'
                  },
                  {
                    id: 'date',
                    name: 'Single Date'
                  }
                ]"></Multiselect>
              </div>
              <div class="col-md-12 mb-2">
                <label for="siteStreet" class="form-label">Message</label>
                <textarea class="form-control" id="noteMessage" rows="5" v-model="note.message"></textarea>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" :id='"closeCreateSiteModal" + id'>Close</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
/* global $ */
import { io } from "socket.io-client";
import axios from "axios";
import scheduled_jobs from "./ScheduleJob.vue";
import ScheduleJobMini from "./ScheduleJobMini.vue";
import {DateTime} from 'luxon';
import Multiselect from '@vueform/multiselect';
export default {
  name: "admin.schedule",
  components: {
    // SecureImage
    scheduled_jobs,
    Multiselect,
    ScheduleJobMini
  },
  data(){
    return {
      calendar: null,
      jobs: [],
      engineers: [],
      scheduled_jobs: [],
      currentWeek: [],
      startOfWeek: null,
      loading: true,
      socket: null,
      token: null,
      userTypeFilter: "Engineer",
      jobFilter: "",
      note: {
        audience: null,
        note: null,
        date: null
      }
    }
  },
  mounted(){
    this.startOfWeek = DateTime.local().startOf('week');
    this.currentWeek.push(this.startOfWeek);
    this.currentWeek.push(this.startOfWeek.plus({days: 1}));
    this.currentWeek.push(this.startOfWeek.plus({days: 2}));
    this.currentWeek.push(this.startOfWeek.plus({days: 3}));
    this.currentWeek.push(this.startOfWeek.plus({days: 4}));
    this.currentWeek.push(this.startOfWeek.plus({days: 5}));
    this.currentWeek.push(this.startOfWeek.plus({days: 6}));
    this.loadEngineers();
    this.loadJobs();
    let pos = { top: 0, left: 0, x: 0, y: 0 };
    let ele = this.$refs.schedule_container;

    const mouseDownHandler = function (e) {
      if(e.target.type === 'textarea') return;
      ele.style.cursor = 'grabbing';
      ele.style.userSelect = 'none';
      pos = {
        // The current scroll
        left: ele.scrollLeft,
        top: ele.scrollTop,
        // Get the current mouse position
        x: e.clientX,
        y: e.clientY,
      };

      document.addEventListener('mousemove', mouseMoveHandler);
      document.addEventListener('mouseup', mouseUpHandler);
    };
    const mouseMoveHandler = function (e) {
      // How far the mouse has been moved
      const dx = e.clientX - pos.x;
      const dy = e.clientY - pos.y;

      // Scroll the element
      ele.scrollTop = pos.top - dy;
      ele.scrollLeft = pos.left - dx;
    };
    const mouseUpHandler = function () {
      document.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('mouseup', mouseUpHandler);

      ele.style.cursor = 'grab';
      ele.style.removeProperty('user-select');
    };

    axios.get(`${process.env.VUE_APP_API_URL}/v1/socket`)
    .then(response => {
      this.token = response.data.token;
      this.socket = io('https://adssocket.azurewebsites.net', {
        'reconnection': true,
        'transports': ['websocket'],
        'path': '/socket'
      });

      this.socket.on('connect', () => {
        console.log("Socket connected");
        this.socket.emit("authentication", {target: "user", token: this.token});
        console.log("Sent authentication");
      });

      this.socket.on('authenticated', () => {
        console.log("Authenticated");

        this.socket.emit('join-schedule', {});

      });

      this.socket.on('disconnect', () => {
        console.log("Socket disconnected");
      })

      this.socket.on('reconnect', () => {
        this.socket.emit("authentication", {target: "user", token: this.token});
      });
    })
    .catch(error => {
      this.$error("Failed to authenticate with Socket. Changes will not be live!", error);
    })

    ele.addEventListener('mousedown', mouseDownHandler);
  },
  methods: {
    isAnnualLeave(day, engineer){
      return this.engineers.find((e) => {
        return e.id === engineer.id;
      }).annualLeave[day.toFormat('ccc dd')] !== undefined;
    },
    openNoteModal(){
      $('#createScheduledNoteModal').modal('show');
    },
    moveEngineer(engineer, direction = 1){
      let index = this.engineers.indexOf(engineer);
      let new_index = index + direction;
      if(index + direction < 0){
        new_index = this.engineers.length - 1;
      }
      if(index + direction > this.engineers.length - 1){
        new_index = 0;
      }
      console.log(`Moving ${engineer.fname} from ${index} to ${new_index}`);
      let newArray = [];
      this.engineers.forEach((e, i) => {
        if(i === new_index){
          newArray.push(engineer);
        }else if(i === index){
          newArray.push(this.engineers[new_index]);
        }else{
          newArray.push(e);
        }
      });
      this.engineers = newArray;
      let engineerOrder = [];
      this.engineers.forEach((e) => {
        engineerOrder.push(e.id);
      });
      localStorage.setItem("link::engineerOrder", JSON.stringify(engineerOrder));
    },
    dragover(event){
      event.preventDefault();
    },
    async drop(event, e, day){
      event.preventDefault();
      let data = JSON.parse(event.dataTransfer.getData("job"));
      console.log(data);
      let currentEngineer = this.engineers.find((engineer) => {
        return engineer.id === data.engineer;
      });
      let currentDay = this.currentWeek.find((d) => {
        return d.toFormat('ccc dd') === data.day;
      });
      let newEngineer = this.engineers.find((engineer) => {
        return engineer.id === e.id;
      });
      let newDay = this.currentWeek.find((d) => {
        return d.toFormat('ccc dd') === day.toFormat('ccc dd');
      });

      if(currentEngineer && currentDay){
        if(currentEngineer?.id === newEngineer.id && currentDay.toFormat('ccc dd') === newDay.toFormat('ccc dd')){
          return;
        }
      }

      let job;
      if(currentEngineer && currentDay){
        job = currentEngineer.jobs[currentDay.toFormat('ccc dd')].find((job) => {
          return parseInt(job.id) === parseInt(data.job);
        });
      }else{
        job = this.jobs.find((job) => {
          return parseInt(job.id) === parseInt(data.job);
        });
      }

      console.log(job);

      let result = await this.$confirm(`Are you sure you want to move JR ${job.id} to ${newEngineer.fname}'s schedule on ${newDay.toFormat('ccc dd')}?`, "Move Job", {
        confirmButtonText: "Move",
        cancelButtonText: "Cancel",
        type: "warning"
      })

      if(result){
        if(newEngineer.jobs[newDay.toFormat('ccc dd')] === undefined){
          newEngineer.jobs[newDay.toFormat('ccc dd')] = [];
        }
        newEngineer.jobs[newDay.toFormat('ccc dd')].push(job);
        if(currentEngineer && currentDay){
          currentEngineer.jobs[currentDay.toFormat('ccc dd')] = currentEngineer.jobs[currentDay.toFormat('ccc dd')].filter((j) => {
            return parseInt(j.id) !== parseInt(job.id);
          });
        }else{
          this.jobs = this.jobs.filter((j) => {
            return parseInt(j.id) !== parseInt(job.id);
          });
        }
        
        this.$success("Job moved successfully!");
      }

      // let jobDate = DateTime.fromSeconds(job.date).toFormat('ccc dd');
      // if(!assignedEngineer.jobs[jobDate]){
      //   assignedEngineer.jobs[jobDate] = [];
      // }
      // assignedEngineer.jobs[jobDate].push(job);
      // assignedEngineer.title_length = Math.max(assignedEngineer.title_length, 25);

    },
    loadJobs(){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/jobs?page=1&search=${this.jobFilter}&showcompleted=false
      &status=-1&counter=1&sort_by=created_at&count=2000`)
      .then(response => {
        response.data.jobs.forEach((job) => {
          job.pending = 1;
        })
        this.jobs = response.data.jobs;
      })
      .catch(error => {
        this.$error("Failed to load jobs", error);
      })
    },
    loadEngineers(){
      let engineerOrder = localStorage.getItem("link::engineerOrder") || "[]";
      engineerOrder = JSON.parse(engineerOrder);
      axios.get(`${process.env.VUE_APP_API_URL}/v1/users/list`)
      .then(response => {
        let users = response.data.users;
        let engineers = users.filter((user) => {
          return user.type === 'Engineer';
        });
        engineers = engineers.sort((a, b) => {
          let aPosition = parseInt(a.position || 0);
          let bPosition = parseInt(b.position || 0);
          return aPosition - bPosition;
        });
        let newEngineerArray = [];
        engineerOrder.forEach((id) => {
          let engineer = engineers.find((e) => {
            return parseInt(e.id) === parseInt(id);
          });
          if(engineer){
            newEngineerArray.push(engineer);
          }
        });
        engineers.forEach((engineer) => {
          if(!newEngineerArray.includes(engineer)){
            newEngineerArray.push(engineer);
          }
        });
        engineers = newEngineerArray;
        let subbies = users.filter((user) => {
          return user.type === 'Sub-Contractor';
        });
        subbies = subbies.sort((a, b) => {
          return a.fname.localeCompare(b.fname);
        });
        let newSubbieArray = [];
        engineerOrder.forEach((id) => {
          let subbie = subbies.find((e) => {
            return parseInt(e.id) === parseInt(id);
          });
          if(subbie){
            newSubbieArray.push(subbie);
          }
        });
        subbies.forEach((subbie) => {
          if(!newSubbieArray.includes(subbie)){
            newSubbieArray.push(subbie);
          }
        });
        subbies = newSubbieArray;
        let staff = users.filter((user) => {
          return user.type === 'Staff';
        });
        staff = staff.sort((a, b) => {
          return a.fname.localeCompare(b.fname);
        });
        let newStaffArray = [];
        engineerOrder.forEach((id) => {
          let staffMember = staff.find((e) => {
            return parseInt(e.id) === parseInt(id);
          });
          if(staffMember){
            newStaffArray.push(staffMember);
          }
        });
        staff.forEach((staffMember) => {
          if(!newStaffArray.includes(staffMember)){
            newStaffArray.push(staffMember);
          }
        });
        staff = newStaffArray;
        this.engineers = engineers.concat(subbies).concat(staff);
        this.engineers.forEach((e) => {
          e.title_length = (e.fname.substr(0, 1) + ' ' + e.lname).length + 8;
          e.name = e.fname + ' ' + e.lname;
        });
        this.loadScheduledJobs();
        this.loadScheduledNotes();
      })
      .catch(error => {
        this.$error("Failed to load engineers", error);
      })
    },
    loadScheduledJobs(){
      this.loading = true;
      axios.get(`${process.env.VUE_APP_API_URL}/v1/schedule/scheduled?start=${this.startOfWeek.toFormat('yyyy-MM-dd')}&end=${this.startOfWeek.plus({days: 6}).toFormat('yyyy-MM-dd')}`)
      .then(response => {
        let jobs = response.data.jobs;
        this.engineers.forEach((e) => {
          e.jobs = {};
        });
        jobs.forEach((job) => {
          let assignedEngineer = this.engineers.filter((e) => {
            return parseInt(e.id) === parseInt(job.user_id);
          })[0];
          if(assignedEngineer){
            let jobDate = DateTime.fromSeconds(job.date).toFormat('ccc dd');
            if(!assignedEngineer.jobs[jobDate]){
              assignedEngineer.jobs[jobDate] = [];
            }
            assignedEngineer.jobs[jobDate].push(job);
            assignedEngineer.title_length = Math.max(assignedEngineer.title_length, 25);
          }
        });
      })
      .catch(error => {
        this.$error("Failed to load scheduled jobs", error);
      })
      .finally(() => {
        this.loading = false;
      });
      this.loadAnnualLeave();
    },
    loadAnnualLeave(){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/userannualleave?start=${this.startOfWeek.toFormat('yyyy-MM-dd')}&end=${this.startOfWeek.plus({days: 6}).toFormat('yyyy-MM-dd')}`)
      .then(response => {
        let leave = response.data.annualLeave;
        console.log('Leave', leave);
        this.engineers.forEach((e) => {
          e.annualLeave = {};
        });
        leave.forEach((leave) => {
          let assignedEngineer = this.engineers.filter((e) => {
            return parseInt(e.id) === parseInt(leave.user_id);
          })[0];
          if(assignedEngineer){
            // Go through each day of the week and add the leave to the engineer

            let startDate = this.startOfWeek;

            leave.start_date = DateTime.fromISO(leave.start_date);
            leave.end_date = DateTime.fromISO(leave.end_date);

            for(let i = 0; i < 7; i++){
              let date = startDate.plus({days: i});
              if(date >= leave.start_date && date <= leave.end_date){
                let leaveDate = date.toFormat('ccc dd');

                if(!assignedEngineer.annualLeave[leaveDate]){
                  assignedEngineer.annualLeave[leaveDate] = [];
                }
                assignedEngineer.annualLeave[leaveDate].push(leave);
              }
            }

            // let leaveDate = DateTime.fromSeconds(leave.date).toFormat('ccc dd');
            // if(!assignedEngineer.annualLeave[leaveDate]){
            //   assignedEngineer.annualLeave[leaveDate] = [];
            // }
            // assignedEngineer.annualLeave[leaveDate].push(leave);
          }
        });
      })
    },
    loadScheduledNotes(){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/schedule/scheduled/notes?start=${this.startOfWeek.toFormat('yyyy-MM-dd')}&end=${this.startOfWeek.plus({days: 6}).toFormat('yyyy-MM-dd')}`)
        .then(response => {
          let notes = response.data.notes;
          this.engineers.forEach((e) => {
            e.notes = {};
          });
          notes.forEach((note) => {
            let assignedEngineer = this.engineers.filter((e) => {
              return parseInt(e.id) === parseInt(note.user_id);
            })[0];
            if(assignedEngineer){
              let jobDate = DateTime.fromSeconds(note.date).toFormat('ccc dd');
              if(!assignedEngineer.notes[jobDate]){
                assignedEngineer.notes[jobDate] = [];
              }
              assignedEngineer.notes[jobDate].push(note);
              assignedEngineer.title_length = Math.max(assignedEngineer.title_length, 25);
            }
          });
        })
        .catch(error => {
          this.$error("Failed to load scheduled notes", error);
        });
    },
    changeWeek(diff){
      if(diff < 0){
        this.startOfWeek = this.startOfWeek.minus({weeks: Math.abs(diff)});
      }
      if(diff > 0){
        this.startOfWeek = this.startOfWeek.plus({weeks: diff});
      }
      this.currentWeek = [];
      this.currentWeek.push(this.startOfWeek);
      this.currentWeek.push(this.startOfWeek.plus({days: 1}));
      this.currentWeek.push(this.startOfWeek.plus({days: 2}));
      this.currentWeek.push(this.startOfWeek.plus({days: 3}));
      this.currentWeek.push(this.startOfWeek.plus({days: 4}));
      this.currentWeek.push(this.startOfWeek.plus({days: 5}));
      this.currentWeek.push(this.startOfWeek.plus({days: 6}));
      this.loadScheduledJobs();
    },
    removeJob(engineer, day, index){

      let realEngineer = this.engineers.filter((e) => {
        return parseInt(e.id) === parseInt(engineer);
      })[0];

      if(realEngineer){
        let array = realEngineer.jobs[day.toFormat('ccc dd')];
        let job = array[index];
        axios.delete(`${process.env.VUE_APP_API_URL}/v1/schedule/${job.id}`, {
          data: {
            undo: false,
            schedule_id: job.schedule_id
          }
        })
            .then(response => {
              if(response.data.pending){
                array = array.splice(index, 1);
              }else{
                job.to_delete = 1;
              }
            })
            .catch(error => {
              this.$error("Failed to delete job", error);
            })
      }

      this.$success("Removed Job");

    },
    undoRemoveJob(engineer, day, index){
      let realEngineer = this.engineers.filter((e) => {
        return parseInt(e.id) === parseInt(engineer);
      })[0];

      if(realEngineer){
        let array = realEngineer.jobs[day.toFormat('ccc dd')];
        let job = array[index];
        axios.delete(`${process.env.VUE_APP_API_URL}/v1/schedule/${job.id}`, {
          data: {
            undo: true,
            schedule_id: job.schedule_id
          }
        })
            .then(() => {
              job.to_delete = 0;
              this.$success("Re-added Job");
            })
            .catch(error => {
              this.$error("Failed to undo deleted job", error);
            })
      }
    },
    addJob(engineer, day, ref){
      let realEngineer = this.engineers.filter((e) => {
        return parseInt(e.id) === parseInt(engineer);
      })[0];

      if(realEngineer){
        let array = realEngineer.jobs[day.toFormat('ccc dd')];
        if(array === undefined){
          realEngineer.jobs[day.toFormat('ccc dd')] = [];
          array = realEngineer.jobs[day.toFormat('ccc dd')];
        }
        let date = day.toFormat('yyyy-MM-dd');
        axios.put(`${process.env.VUE_APP_API_URL}/v1/schedule/${$(this.$refs[ref]).val()}`, {
          engineer_id: engineer,
          date: date
        })
            .then(response => {
              array.push(response.data.job);
              this.$success("Added job");
              $(this.$refs[ref]).val('');
            })
            .catch(error => {
              this.$error("Failed to add note", error);
            })

      }
    },
    removeNote(engineer, day, index){

      let realEngineer = this.engineers.filter((e) => {
        return parseInt(e.id) === parseInt(engineer);
      })[0];

      if(realEngineer){
        let array = realEngineer.notes[day.toFormat('ccc dd')];
        let note = array[index];
        axios.delete(`${process.env.VUE_APP_API_URL}/v1/schedule/note/${note.id}`, {
          data: {
            undo: false
          }
        })
        .then(response => {
          if(response.data.pending){
            array = array.splice(index, 1);
          }else{
            note.to_delete = 1;
          }
        })
        .catch(error => {
          this.$error("Failed to delete note", error);
        })
      }

      this.$success("Removed Note");

    },
    undoRemoveNote(engineer, day, index){
      let realEngineer = this.engineers.filter((e) => {
        return parseInt(e.id) === parseInt(engineer);
      })[0];

      if(realEngineer){
        let array = realEngineer.notes[day.toFormat('ccc dd')];
        let note = array[index];
        axios.delete(`${process.env.VUE_APP_API_URL}/v1/schedule/note/${note.id}`, {
          data: {
            undo: true
          }
        })
            .then(() => {
              note.to_delete = 0;
              this.$success("Re-added Note");
            })
            .catch(error => {
              this.$error("Failed to undo deleted note", error);
            })
      }
    },
    addNote(engineer, day, ref){
      let realEngineer = this.engineers.filter((e) => {
        return parseInt(e.id) === parseInt(engineer);
      })[0];

      if(realEngineer){
        let array = realEngineer.notes[day.toFormat('ccc dd')];
        if(array === undefined){
          realEngineer.notes[day.toFormat('ccc dd')] = [];
          array = realEngineer.notes[day.toFormat('ccc dd')];
        }
        let date = day.toFormat('yyyy-MM-dd');
        axios.put(`${process.env.VUE_APP_API_URL}/v1/schedule/note`, {
          engineer_id: engineer,
          note: $(this.$refs[ref]).val(),
          date: date
        })
        .then(response => {
            array.push(response.data.note);
            this.$success("Added note");
            $(this.$refs[ref]).val('');
        })
        .catch(error => {
          this.$error("Failed to add note", error);
        })
      }

      this.$success("Re-added Job");
    }
  },
  computed: {
    filteredUsers(){
      return this.engineers.filter((e) => {
        return e.type !== 'Staff - No Schedule';
      });
    },
    filteredJobs(){
      return this.jobs.filter((job) => {
        if(this.jobFilter === '') return true;
        let id = job.id.includes(this.jobFilter);
        let description = job.description?.toLowerCase().includes(this.jobFilter.toLowerCase());
        let client = job.client_name?.toLowerCase().includes(this.jobFilter.toLowerCase());
        let site = job.site_name?.toLowerCase().includes(this.jobFilter.toLowerCase());
        let postcode = job.site_postcode?.toLowerCase().includes(this.jobFilter.toLowerCase());
        let town = job.site_town?.toLowerCase().includes(this.jobFilter.toLowerCase());
        return id || description || client || site || postcode || town;
      });
    },
  }
}
</script>

<style scoped>
  .job-item {
    margin-bottom: 0;
    color: white;
  }
  .job {
    touch-action: none;
    user-select: none;
  }
  .job-number:hover{
    color: deepskyblue;
    text-decoration: underline;
  }

  .published {
    color: #17a00e;
    border: 1px solid #17a00e;
    padding: 10px;
  }

  .not-published {
    color: #ffca2c;
    border: 1px solid #ffca2c;
    padding: 10px;
  }

  .to_delete {
    color: #d10b1e;
    border: 1px solid #d10b1e;
    padding: 10px;
  }

  .schedule-hotbar {
    position: fixed;
    top: 5px;
    left: 30%;
    right: 30%;
    height: 3rem;
    width: 40%;
    z-index: 100;
  }

  .schedule-entry {
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px;
  }

  .schedule-clearfix {
    height: 100px;
    width: 100%;
  }

  .card {
    margin-bottom: 0.5rem;
  }

  .card-body {
    padding: 0.1rem;
  }

  .schedule-table th {
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: black;
  }

  .schedule-table td:nth-child(1),
  .schedule-table th:nth-child(1) {
    position: sticky;
    left: 0;
    z-index: 100;
    background-color: black;
  }

  .disabled-day {
    background-color: rgba(220, 54, 68, 0.7);
    border: 1px solid #dc3644;
  }
</style>
<style src="@vueform/multiselect/themes/default.css"></style>