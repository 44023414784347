<template>
    <div @dragstart="dragstart" @dragend="dragend" draggable="true" :key="engineer.id + '-engineer-day-' + job.id" class="card text-white" :class="{'dragging': dragging}">
        <div class="card-body">
            <div>
                <h5 class="job-header" @click="$router.push({name: 'admin.jobs.view', params: {
                    id: job.id
                }})">JR #{{ job.id }}</h5>
            </div>
            <p class="card-text"><strong>{{ job.client_name }}</strong> - {{ job.site_name }} - {{ job.site_postcode }}</p>
            <p class="card-text">
                {{ job.description }}
            </p>
            <p class="card-text">
                <span class="badge" :class="`${status.class}`">{{ status.label }}</span>
                <span class="badge bg-primary" v-for="user in job.users" :key="`job-${job.id}-user-${user.id}`">{{ user.fname }} {{ user.lname }}</span>
            </p>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ScheduleJob',
    props: {
        job: {
            type: Object,
            required: true
        },
        engineer: {
            type: [Object, Number],
            required: true
        },
        day: {
            type: [String, Number],
            required: true
        },
    },
    data(){
        return {
            dragging: false,
        }
    },
    methods: {
        dragstart(event){
            this.dragging = true;
            let day = -1;
            if(this.day !== -1){
                day = this.day.toFormat('ccc dd');
            }
            event.dataTransfer.setData("job", JSON.stringify({
                job: this.job.id,
                engineer: this.engineer?.id || -1,
                day: day
            }));
        },
        dragend(){
            this.dragging = false;
        }
    },
    computed: {
        status: function(){
            switch(this.job.status){
                case null:
                case 0:
                    return {label: 'TBA', class: 'bg-secondary'};
                case 5:
                    return {label: 'Requires Prep', class: 'bg-warning text-dark'};
                case 1:
                    return {label: 'Completed', class: 'bg-success'};
                case 2:
                    return {label: 'Assigned', class: 'bg-info'};
                case 3:
                    return {label: 'Rejected', class: 'bg-danger'};
                case 6:
                    return {label: 'Quoted - Chase', class: 'bg-info'};
                case 7:
                    return {label: 'Quoted - Close', class: 'bg-success'};
                default:
                    return {label: 'Unknown', class: 'bg-secondary'};
            }
        }
    }
}
</script>

<style scoped>
.card-body {
    padding: 0.5rem;
  }
.card-text {
    padding-left: 0.2rem;
    padding-right: 0.2rem;
}
.card {
    border: 1px solid white;
}
.job-header {
    cursor: pointer;
    max-width: 150px;
}
.job-header:hover {
    text-decoration: underline;
}
.badge {
    margin-right: 0.4rem;
}
</style>